export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const START_DATE = '01.01.2020';
export const NUMBER_OF_PAGE_TO_SHOW = 5;
export const NUMBER_OF_ITEM_PER_PAGE = 10;
export const NUMBER_OF_MEDIA_PER_PAGE = 12;
export const ALL = 'All';
export const EURO_SIGN = '€';
export const GENERAL_MAX_ALLOWED_FILES_UPLOAD = 5;
export const INSIGHT_DOCUMENT_START_YEAR = 2019;
export const STATUS_NOT_SPECIFIED = 'NS'; //Not Specified

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
  None = '',
}
export enum PaymentStatus {
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  Failed = 'Failed',
  Done = 'Done',
}
export enum ProgramStatus {
  Published = 'Published',
  Draft = 'Draft',
}
export enum VacancyStatus {
  Published = 'Published',
  Draft = 'Draft',
}
export enum ApplicationStatus {
  NotSpecified = 'NS',
  Rejected = 'RE',
  Accepted = 'AC',
  Pending = 'PE',
}
export const MAX_RECEIPT_SELECTION = 24;
export const MAX_UPLOAD_SIZE = 5; // 5 MB
export const GENERAL_DOCUMENTS_MAX_UPLOAD_SIZE = 60; // 60 MB
export const DEFAULT_LANGUAGE = 'de';
export const DEFAULT_LANGUAGE_ID = 2;
export const ENGLISH_LANGUAGE = 'en';
export const ENGLISH_LANGUAGE_ID = 1;

export const ALL_DOCUMENTS_IMAGE = 'IMG_DOC';
export const ALL_IMAGE_VIDEO = 'IMG_VID';
export enum UploadMediaType {
  Image = 'image',
  Video = 'video',
  Documents = 'document',
  ImageVideo = 'multiple',
  All = 'all',
}
export enum ServiceMediaType {
  Image = 'IMG',
  Video = 'VID',
  Document = 'DOC',
}
export const ALLOWED_EXTENSIONS = {
  video: ['mp4', 'mov'],
  image: ['jpg', 'png', 'jpeg', 'gif'],
  document: ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'txt', 'png'],
};

// Program
export const PROGRAM_TITLE_MAX_CHARACTER = 150;
export const SEO_TITLE_MAX_CHARACTER = 70;
export const SEO_DESCRIPTION_MAX_CHARACTER = 155;

//Text Editor
export const TEXT_EDITOR_EMPTY = '<p><br></p>';

//#region CMS
export const CMS_IMAGE_FILE_TYPE_ID = 1;
export const CMS_VIDEO_FILE_TYPE_ID = 2;
//#endregion

export enum CategoryType {
  Vacancy = 'VAC',
  FAQ = 'FAQ',
}

export enum UploaderStatus {
  New = 'new',
  Uploaded = 'uploaded',
  CurrentlyUploaded = 'currently_uploaded',
  Failed = 'failed',
  Uploading = 'uploading',
  Updated = 'updated',
  FailedUpdated = 'failed_updated',
}

export enum ContactMsgStatus {
  Responded = 'Responded',
  Pending = 'Pending',
}
export enum PaymentMethods {
  CreditCard = 'Credit Card',
  WireTransfer = 'Wire Transfer',
  Paypal = 'Paypal',
  Sofort = 'Sofort',
  SEPA = 'SEPA Direct Debit',
}
export enum ContactMsgSource {
  SwingwithYourHeart = 'SWY',
  CorporateSocialResponsibility = 'CSR',
  SpecialDonations = 'SPD',
  ContributeYourDividends = 'COD',
}
export enum EnableNextPre {
  Enable = '1',
  Disable = '0',
}

export type PackageType = 'ECE' | 'ICH' | 'ETH' | 'TOH' | 'GPT' | 'CHC';
